import { gql, useQuery } from 'urql';

import { useQueryParamsWithTime } from '@/hooks';
import { mapVariables } from '@/utils';
import { makeOrderBy, makePagination } from '@/utils/pagination';
import {
  SourceOfWealth,
  SourceOfWealthVariables,
} from './__generated__/SourceOfWealth';
import { queryParams } from './queryParams';

const QUERY = gql`
  query SourceOfWealth(
    $after: String
    $before: String
    $amlLevel: AmlRiskLevel
    $complianceStatus: PlayerComplianceStatus
    $first: Int
    $last: Int
  ) {
    viewer {
      id
      playerCompliancePlayersV2(
        after: $after
        before: $before
        amlLevel: $amlLevel
        complianceStatus: $complianceStatus
        first: $first
        last: $last
      ) {
        pageInfo {
          endCursor
          hasNextPage
          startCursor
          hasPreviousPage
        }
        edges {
          node {
            playerGlobalId
            risk
            status
            needsManualReview
            startedAt
            submittedAt
            players {
              playerId
              playerUUID
              brand {
                name
              }
            }
          }
        }
      }
    }
  }
`;

export default function useSourceOfWealth() {
  const [query, setQuery] = useQueryParamsWithTime(queryParams);

  const [{ data, fetching }, refresh] = useQuery<
    SourceOfWealth,
    SourceOfWealthVariables
  >({
    query: QUERY,
    variables: mapVariables(query),
  });

  return {
    refresh,
    fetching,
    defaultFilter: query,
    updateFilter: (values: {}) => setQuery(values, 'replaceIn'),
    ...makeOrderBy(query, setQuery),
    ...makePagination(
      query,
      setQuery,
      data?.viewer.playerCompliancePlayersV2.pageInfo,
    ),
    entries: data?.viewer.playerCompliancePlayersV2?.edges?.map(
      (edge) => edge?.node,
    ),
  };
}
