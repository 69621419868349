import { Link, graphql, useStaticQuery } from 'gatsby';
import React, { FC } from 'react';

import { useGetPlayerOverviewPageLink } from '@/bits/links/useLink';
import {
  Card,
  CardBody,
  CardOptions,
  CardOptionsButton,
  ControlledModal,
  InlineIconButton,
  Table,
} from '@/components';
import { useDataGrid } from '@/components/DataGrid';
import {
  EyeIcon,
  NextIcon,
  PreviousIcon,
  RefreshIcon,
} from '@/components/icons';
import { useTranslate } from '@/contexts';
import { AmlRiskLevel, PlayerComplianceStatus } from '@/globalTypes';
import { SanitySourceOfWealthBlockStaticQuery } from './__generated__/SanitySourceOfWealthBlockStaticQuery';
import useSourceOfWealth from './useSourceOfWealth';

const BLOCK_STATIC_QUERY = graphql`
  query SanitySourceOfWealthBlockStaticQuery {
    sanitySourceOfWealthBlock {
      title {
        ...LocaleString
      }
    }
  }
`;

const SourceOfWealthBlock: FC = () => {
  const { t } = useTranslate();

  const block = useStaticQuery<SanitySourceOfWealthBlockStaticQuery>(
    BLOCK_STATIC_QUERY,
  ).sanitySourceOfWealthBlock;

  const getPlayerOverviewLink = useGetPlayerOverviewPageLink();

  const {
    entries,
    fetching,
    nextPage,
    previousPage,
    refresh,
    orderBy,
    desc,
    setOrderBy,
    defaultFilter,
    updateFilter,
  } = useSourceOfWealth();

  const { DataGrid, GlobalCardOptions } = useDataGrid({
    name: 'sourceOfWealth',
    data: entries,
    fetching,
    orderBy,
    setOrderBy,
    desc,
    defaultFilter,
    updateFilter,
    schema: (s) => [
      s.stringValue({
        field: 'playerGlobalId',
        title: 'Player ID',
      }),
      s.enumValue({
        field: 'risk',
        title: 'Risk',
        filterField: 'amlLevel',
        e: AmlRiskLevel,
      }),
      s.enumValue({
        field: 'status',
        title: 'Status',
        filterField: 'complianceStatus',
        e: PlayerComplianceStatus,
      }),
      s.stringValue({
        field: 'brands',
        title: 'Brands',
        wrap: ({ row }) => (
          <div className="flex items-center">
            {row.players.length}
            <ControlledModal
              content={
                <Card title="Player brands" size="lg">
                  <div className="p-3">
                    <Table
                      headings={['Player ID', 'Brand']}
                      rows={row.players.map((player) => ({
                        key: player.playerId,
                        data: [
                          <Link
                            className="w-full h-full p-3 block"
                            to={getPlayerOverviewLink(player.playerId) ?? ''}
                          >
                            {player.playerUUID}
                          </Link>,
                          player.brand.name,
                        ],
                      }))}
                    />
                  </div>
                </Card>
              }
            >
              <InlineIconButton>
                <EyeIcon />
              </InlineIconButton>
            </ControlledModal>
          </div>
        ),
      }),
    ],
  });

  if (!block) {
    return null;
  }

  return (
    <Card
      size="lg"
      title={t(block.title)}
      showOptionsAtBottom
      options={
        <CardOptions>
          <CardOptionsButton
            disabled={!previousPage}
            onClick={() => previousPage && previousPage()}
          >
            <PreviousIcon />
          </CardOptionsButton>
          <CardOptionsButton
            disabled={!nextPage}
            onClick={() => nextPage && nextPage()}
          >
            <NextIcon />
          </CardOptionsButton>
          <GlobalCardOptions />
          <CardOptionsButton
            onClick={() => refresh({ requestPolicy: 'network-only' })}
          >
            <RefreshIcon />
          </CardOptionsButton>
        </CardOptions>
      }
    >
      <CardBody>
        <DataGrid />
      </CardBody>
    </Card>
  );
};

export default SourceOfWealthBlock;
