import { AmlRiskLevelParam, PlayerComplianceStatusParam } from '@/utils/query/params';
import {
  BooleanParam,
  NumberParam,
  StringParam,
  withDefault,
} from 'use-query-params';

export const queryParams = {
  after: StringParam,
  amlLevel: AmlRiskLevelParam,
  before: StringParam,
  complianceStatus: PlayerComplianceStatusParam,
  desc: withDefault(BooleanParam, true),
  first: withDefault(NumberParam, 20),
  last: NumberParam,
};
